import React, { useState } from "react";
import { auth } from "../firebase";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  root: {
    minHeight: "90vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [form, setForm] = useState({
    email: "",
  });

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      auth
        .sendPasswordResetEmail(form.email)
        .then(() => {
          setLoading(false);
          setForm({
            email: "",
          });
          toast.success("Check your email for reset link!");
        })
        .catch(() => {
          setLoading(false);
          setError("No account with this email was found.");
          toast.error("No account with this email was found.");
        });
    } catch (error) {
      setError("No account with this email was found.");
      setLoading(false);
      toast.error("No account with this email was found.");
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar} />
            <Typography component="h1" variant="h5">
              Request Password Reset
            </Typography>
            <form
              onSubmit={onSubmit}
              className={classes.form}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={form.email}
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
              <Typography
                color="error"
                component="p"
                style={{ height: "25px" }}
              >
                {error}
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Reset Password
                </Button>
              )}
              <Grid container justify="space-between">
                <Grid item>
                  <Link href="/register" variant="body2">
                    Don&#39;t have an account? Register
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/login" variant="body2">
                    Or login here!
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
