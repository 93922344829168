import { gql } from "@apollo/client";

export const GET_MAIN_DASH = gql`
  query mainDash {
    mainDash {
      leagues {
        id
        league_code
        name
        game {
          name
          game_code
        }
        commissioner {
          email
          uid
        }
        img
      }
      ranks {
        rank_code
        rank_name
        game_code
      }
      games {
        game_code
        name
      }
    }
  }
`;
