import React from "react";
import { signOutUser, useAuthState } from "../firebase";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: "10vh",
      flexGrow: 1,
    },
    menuButton: {
      paddingRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const Header = () => {
  const history = useHistory();
  const { isAuthenticated } = useAuthState();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDashboard = () => {
    setAnchorEl(null);
    history.push("/dashboard");
  };

  const handleLogout = () => {
    setAnchorEl(null);
    signOutUser();
  };

  return (
    <>
      <div className="mainHeader">
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                GG E-Sports Leagues
              </Typography>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuRoundedIcon />
              </Button>
              {isAuthenticated ? (
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleDashboard} href="/dashboard">
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout} href="/login">
                    Logout
                  </MenuItem>
                </Menu>
              ) : (
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      history.push("/login");
                    }}
                    href="/login"
                  >
                    Login
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      history.push("/register");
                    }}
                    href="/register"
                  >
                    Register
                  </MenuItem>
                </Menu>
              )}
            </Toolbar>
          </AppBar>
        </div>
      </div>
    </>
  );
};

export default Header;
