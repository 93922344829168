import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { REGISTER } from "../queries/auth_queries";
import { auth } from "../firebase";

// Material-UI Imports:
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  root: {
    minHeight: "90vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Register = () => {
  const [error, setError] = useState("");
  const [register] = useMutation(REGISTER, {
    onCompleted({ register }) {
      if (register) {
        history.push("/dashboard");
      } else {
        setError("There is already an account associated with this email");
      }
    },
  });

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const history = useHistory();

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    if (form.password === form.confirmPassword) {
      try {
        auth
          .createUserWithEmailAndPassword(form.email, form.password)
          .then(({ user }: { user: any }) =>
            user.getIdToken().then((idToken: any) => {
              register({ variables: { idToken } });
            })
          )
          .catch((error: any) => {
            console.log({ error });
            setLoading(false);
            setError(
              "An account with this email already exists, did you mean to login?"
            );
          });
      } catch (error) {
        setLoading(false);
        setError(
          "An account with this email already exists, did you mean to login?"
        );
      }
    } else {
      setLoading(false);
      setError("The passwords you entered do not match.");
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar} />
            <Typography component="h1" variant="h5">
              Register
            </Typography>
            <form
              onSubmit={onSubmit}
              className={classes.form}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={form.email}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={form.password}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    value={form.confirmPassword}
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
              <Typography
                color="error"
                component="p"
                style={{ height: "25px" }}
              >
                {error}
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Register
                </Button>
              )}
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Already have an account? Login
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Register;
