import { gql } from "@apollo/client";

export const CREATE_LEAGUE = gql`
  mutation createLeague($payload: LeagueInput!) {
    createLeague(payload: $payload) {
      id
      name
      visibility
      game {
        name
      }
      commissioner {
        email
      }
    }
  }
`;
