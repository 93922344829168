import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { GET_MAIN_DASH } from "../queries/page_queries";
import { CREATE_LEAGUE } from "../queries/create_queries";
import { Formik, Form } from "formik";
import firebase, { storage } from "../firebase";
import ImageCropper from "../reusable_components/ImageCropper";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem } from "@material-ui/core";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minHeight: "90vh",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    menuButton: {
      paddingRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    buttonContainer: {
      textAlign: "center",
      alignItems: "center",
    },
    createLeagueModal: {
      height: "100vh",
      width: "100vw",
    },
    paper: {
      backgroundColor: "white",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inputs: {
      padding: "0 8px",
    },
    formControl: {
      margin: "auto",
      minWidth: 120,
      width: "80vw",
      display: "flex",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    textCenter: {
      textAlign: "center",
    },
    leagueImage: {
      border: "1px solid black",
      borderRadius: "50%",
      maxWidth: "100px",
    },
  })
);

const Dashboard = () => {
  const classes = useStyles();
  const [ranks, setRanks] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [leagueImg, setLeagueImg] = useState<any>();
  const [blob, setBlob] = useState<any>(null);
  const [cropImage, setCropImage] = useState(false);
  const getBlob = (blob: any) => {
    setBlob(blob);
    setCropImage(false);
  };

  const onImgChange = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setLeagueImg(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
    setCropImage(true);
  };
  const { loading, error, data } = useQuery(GET_MAIN_DASH);
  const [createLeague] = useMutation(CREATE_LEAGUE, {
    update(cache, { data: { createLeague } }) {
      cache.modify({
        fields: {
          mainDash(dash) {
            const newLeagueRef = cache.writeFragment({
              data: createLeague,
              fragment: gql`
                fragment NewLeague on leagues {
                  id
                  name
                  game {
                    name
                  }
                  commissioner {
                    email
                  }
                  img
                }
              `,
            });
            return { ...dash, leagues: [newLeagueRef, ...dash.leagues] };
          },
        },
      });
    },
    onCompleted() {
      handleClose();
    },
  });

  useEffect(() => {
    if (data) {
      setRanks(data.mainDash.ranks);
    }
  }, [data]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Container component="main">
          {loading ? (
            <div>
              <p>Loading...</p>
            </div>
          ) : error ? (
            <Redirect to="/login" />
          ) : (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={6} className={classes.buttonContainer}>
                  <Button>Join A League</Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonContainer}>
                  <Button onClick={handleOpen}>Create A League</Button>
                </Grid>
              </Grid>

              <Typography>Leagues</Typography>
              <Grid container spacing={3}>
                {data.mainDash.leagues.map((league: any) => {
                  return (
                    <Grid key={league.id} item xs={12} md={6} lg={4}>
                      <Card variant="outlined">
                        <CardContent>
                          <Grid container>
                            <Grid item sm={8}>
                              <Typography color="textSecondary" gutterBottom>
                                League Name: {league.name}
                              </Typography>
                              <Typography>
                                Game Type: {league.game.name}
                              </Typography>
                              <Typography>
                                Commissioner: {league.commissioner.email}
                              </Typography>
                              <Button
                                href={`/dashboard/${league.league_code}`}
                                size="small"
                              >
                                View League
                              </Button>
                            </Grid>
                            <Grid item sm={4}>
                              <img
                                className={classes.leagueImage}
                                src={league.img}
                                alt="Custom League"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.createLeagueModal}
          >
            <div className={classes.paper}>
              <Formik
                initialValues={{
                  name: "",
                  img: "",
                  game_code: "",
                  rank_code: "",
                  visibility: "",
                  teams_player_count: "",
                }}
                validate={(values) => {
                  const errors: any = {};
                  if (!values.name) {
                    errors.name = "Required";
                  }
                  if (!values.game_code) {
                    errors.game_code = "Required";
                  }
                  if (!values.rank_code) {
                    errors.rank_code = "Required";
                  }
                  if (!values.visibility) {
                    errors.visibility = "Required";
                  }
                  if (!values.teams_player_count) {
                    errors.teams_player_count = "Required";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  if (blob) {
                    const storageRef = storage.ref();
                    const uploadTask = storageRef
                      .child("league-images/" + leagueImg.name)
                      .put(blob, { contentType: blob.type });

                    uploadTask.on(
                      firebase.storage.TaskEvent.STATE_CHANGED,
                      (snapshot: any) => {},
                      (error: any) => {
                        throw error;
                      },
                      () => {
                        uploadTask.snapshot.ref
                          .getDownloadURL()
                          .then(async (url: string) => {
                            values.img = url;
                            createLeague({
                              variables: { payload: { ...values } },
                            });
                          });
                      }
                    );
                    setSubmitting(false);
                  } else {
                    createLeague({
                      variables: { payload: { ...values } },
                    });
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <FormControl
                          className={`${classes.formControl} ${classes.textCenter}`}
                        >
                          <Typography component="h1">
                            Create A League
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            label="League Name:"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            style={{ color: "red" }}
                          >
                            {errors.name && touched.name && errors.name}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="gameType-label">Game:</InputLabel>
                          <Select
                            labelId="gameType-label"
                            name="game_code"
                            id="game_code"
                            onChange={(e) => {
                              const { value } = e.target;
                              const filteredRanks = ranks.filter(
                                (rank: any) => rank.game_code === value
                              );
                              setRanks(filteredRanks);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.game_code}
                          >
                            <MenuItem>Select A Game</MenuItem>
                            {data &&
                              data.mainDash.games.map((game: any) => {
                                return (
                                  <MenuItem
                                    key={game.game_code}
                                    value={game.game_code}
                                  >
                                    {game.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            style={{ color: "red" }}
                          >
                            {errors.game_code &&
                              touched.game_code &&
                              errors.game_code}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="rank-label">Rank:</InputLabel>
                          <Select
                            labelId="rank-label"
                            name="rank_code"
                            id="rank_code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.rank_code}
                          >
                            <MenuItem>Select League Rank</MenuItem>
                            {ranks &&
                              ranks.map((rank: any) => {
                                return (
                                  <MenuItem
                                    key={rank.rank_code}
                                    value={rank.rank_code}
                                  >
                                    {rank.rank_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            style={{ color: "red" }}
                          >
                            {errors.rank_code &&
                              touched.rank_code &&
                              errors.rank_code}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="label-visibility">
                            Visibility:
                          </InputLabel>
                          <Select
                            labelId="label-visibility"
                            id="visibility"
                            name="visibility"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.visibility}
                          >
                            <MenuItem value="public">Public</MenuItem>
                            <MenuItem value="private">Private</MenuItem>
                          </Select>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            style={{ color: "red" }}
                          >
                            {errors.visibility &&
                              touched.visibility &&
                              errors.visibility}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            label="Team Players Count"
                            id="teams_player_count"
                            type="number"
                            name="teams_player_count"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.teams_player_count}
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            style={{ color: "red" }}
                          >
                            {errors.teams_player_count &&
                              touched.teams_player_count &&
                              errors.teams_player_count}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <input
                            type="file"
                            name="img"
                            accept="image/*"
                            onChange={(event: any) => {
                              onImgChange(event);
                              setFieldValue("img", event.target.files[0]);
                            }}
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            style={{ color: "red" }}
                          >
                            {errors.img && touched.img && errors.img}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <Grid container className={classes.buttonContainer}>
                            <Grid item sm={6}>
                              <Button type="submit" disabled={isSubmitting}>
                                Submit
                              </Button>
                            </Grid>
                            <Grid item sm={6}>
                              <Button
                                onClick={handleClose}
                                disabled={isSubmitting}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              {leagueImg && cropImage && (
                <ImageCropper getBlob={getBlob} inputImg={leagueImg} />
              )}
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
