import { gql } from "@apollo/client";

export const REGISTER = gql`
  mutation register($idToken: String!) {
    register(idToken: $idToken)
  }
`;

export const LOGIN = gql`
  mutation login($idToken: String!) {
    login(idToken: $idToken)
  }
`;
