import React, { useState, useCallback } from "react";
import { Button } from "@material-ui/core";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImage";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cropComponent: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    saveButton: {
      position: "absolute",
      textAlign: "center",
      left: 0,
      right: 0,
      top: 20,
      zIndex: 999,
    },
  })
);

const ImageCropper = ({
  getBlob,
  inputImg,
}: {
  getBlob: any;
  inputImg: any;
}) => {
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const onSaveCrop = async () => {
    const croppedImage = await getCroppedImg(inputImg, croppedAreaPixels);
    getBlob(croppedImage);
  };

  return (
    <>
      <div className={classes.cropComponent}>
        <Cropper
          image={inputImg}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
        <div className={classes.saveButton}>
          <Button
            style={{
              backgroundColor: "purple",
              color: "white",
              boxShadow: "1px 1px 3px black",
              borderRadius: "10px",
            }}
            onClick={onSaveCrop}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default ImageCropper;
