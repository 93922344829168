import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../firebase";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../queries/auth_queries";

// Material-UI Imports for styling:
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  root: {
    minHeight: "90vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const [login] = useMutation(LOGIN, {
    onCompleted({ login }) {
      if (login) {
        history.push("/dashboard");
      } else {
        setError("Unable to login with this email/password combination");
      }
    },
  });

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const history = useHistory();
  const onChange = (e: any) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      auth
        .signInWithEmailAndPassword(form.email, form.password)
        .then(({ user }: { user: any }) =>
          user.getIdToken().then((idToken: any) => {
            login({ variables: { idToken } });
          })
        )
        .catch((err: any) => {
          if (err.code === "auth/wrong-password") {
            setLoading(false);
            setError(
              "This email/password combination does not exist, please try again."
            );
          } else {
            setLoading(false);
            setError(
              "An account with this email does not exist please register first."
            );
          }
        });
    } catch (error) {
      setLoading(false);
      setError(
        "An account with this email does not exist please register first"
      );
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar} />
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <form
              onSubmit={onSubmit}
              className={classes.form}
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={form.email}
                onChange={onChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={form.password}
                onChange={onChange}
              />
              <Typography
                color="error"
                component="p"
                style={{ height: "25px" }}
              >
                {error}
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Login
                </Button>
              )}
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2">
                    Don&#39;t have an account? Register
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Login;
