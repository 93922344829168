import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: "10vh",
      flexGrow: 1,
    },
    menuButton: {
      paddingRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const LeagueDashboard = () => {
  const { league_code }: { league_code: string } = useParams();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    console.log(league_code);
  }, [league_code]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color={"secondary"}>
        <Toolbar style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" className={classes.title}>
            GG E-Sports Leagues
          </Typography>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MenuRoundedIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} href="/dashboard">
              League Home
            </MenuItem>
            <MenuItem onClick={handleClose} href="/login">
              Stats
            </MenuItem>
            <MenuItem onClick={handleClose} href="/login">
              Schedule
            </MenuItem>
            <MenuItem onClick={handleClose} href="/login">
              League Leaders
            </MenuItem>
            <MenuItem onClick={handleClose} href="/login">
              Teams
            </MenuItem>
            <MenuItem onClick={handleClose} href="/login">
              Playoffs
            </MenuItem>
            <MenuItem onClick={handleClose} href="/login">
              Past Seasons
            </MenuItem>
            <MenuItem onClick={handleClose} href="/login">
              Team Settings
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default LeagueDashboard;
