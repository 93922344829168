import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

export type PrivateRouteProps = {
  component: any;
} & RouteProps;

export default function PrivateRoute({
  component: Component,
  ...props
}: PrivateRouteProps) {
  const [user, loading, error] = useAuthState(auth);

  return (
    <Route
      {...props}
      render={(routeProps) =>
        (loading || user) && !error ? (
          <Component {...routeProps} loading={loading} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
