import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
import PublicRoute from "./auth/PublicRoute";
import Header from "./public_components/Header";
import Register from "./public_components/Register";
import Login from "./public_components/Login";
import ForgotPassword from "./public_components/ForgotPassword";
import Dashboard from "./private_components/Dashboard";
import LeagueDashboard from "./private_components/LeagueDashboard";
import { AuthContextProvider } from "./firebase";
import CssBaseline from "@material-ui/core/CssBaseline";

function App() {
  return (
    <AuthContextProvider>
      <Router>
        <CssBaseline />
        <Header />
        <Switch>
          <PublicRoute exact path="/register" component={Register} />
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute
            path="/dashboard/:league_code"
            component={LeagueDashboard}
          />
        </Switch>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
